@tailwind base
@tailwind components
@tailwind utilities

@import fonts


body
  
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
