

@font-face
  font-family: 'futuraStd-book'
  src: url('../assets/fonts/FuturaStd-Book.otf'),
  font-weight: normal
  font-style: normal
  font-display: block

@font-face
  font-family: 'akzidenzGroteskPro'
  src: url('../assets/fonts/AkzidenzGroteskPro-Ext.otf'),
  font-weight: normal
  font-style: normal
  font-display: block

